export const refs = {
  inputCity: document.querySelector('#inputCity'),
  listMenu: document.querySelector('#listMenu'),
  inputDepartment: document.querySelector('#inputDepartment'),
  listMenuDepartment: document.querySelector('#listMenuDepartament'),
  expandableBlock: document.getElementById('expandable-block'),
  followingContent: document.querySelector('.following-content'),
  selectDepartament: document.querySelector('#select-departament'),
  sendCode: document.querySelector('#button-send-code'),
  sendPass: document.querySelector('#button-send-pass'),
  sendWithoutCode: document.querySelector('#button-send-without-code'),
  sendClose: document.querySelector('#button-close'),
  textChangePhone: document.querySelector('#changePhone'),
  textChangePhonePass: document.querySelector('#changePhonePass'),
  inputCode: document.querySelector('#inputCode'),
  inputPass: document.querySelector('#inputPass'),
};
